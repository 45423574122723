import { updateWhoVieWedmed } from "../../services/apis/dispense/updatewhoviewedmed";

export function getCurrentDay() {
	const currentDay = new Date();

	const options = {
		timeZone: 'America/Vancouver',
		year: 'numeric',
		month: 'long',
		day: 'numeric',
	};

	const canadaTime = currentDay.toLocaleString('en-US', options);
	return canadaTime;
}

export const DisplayCurrentDay = (setCurrentDate) => {
	const currentCanadaTime = getCurrentDay();
	setCurrentDate(currentCanadaTime)
}

export function getLocation(setCordinate) {
	if (navigator.geolocation) {
		navigator.geolocation.getCurrentPosition((position) => showPosition(position, setCordinate));
	} else {
	}
}

function showPosition(position, setCordinate) {
	setCordinate({
		latitude: position.coords.latitude,
		longitude: position.coords.longitude
	});
}

export const getDefaultPharmacist = (pharmacyId, driverDefaultPharmacist) => {
	return driverDefaultPharmacist?.filter(item => item?.pharmacy_id === pharmacyId);
};



export const handleToggleRXData = (
	index,
	data,
	openIndex,
	setOpenIndex,
	dispatch,
	clearSelectedPharmacistID,
	setSignatureErrors,
	setShowData,
	RemoveAllPatchesId,
	setPatientSignature,
	setShowComment,
	setShowPatientSignature,
	setShowPharmacistSignature,
	setPharmacistSignature,
	setComment,
	setPId,
	pharmacyId
) => {
	
	if (openIndex === index) {
		setOpenIndex(null);
		dispatch(clearSelectedPharmacistID(null));
	}

	else {
		setOpenIndex(index);
		dispatch(clearSelectedPharmacistID(null));
		setSignatureErrors((prevState) => ({
			...prevState,
			patientSign: null,
			pharmacistID: null,
			pharmacistSign: null,
		}));

		const requestedData = {
			pharmacy_id: pharmacyId,
			patient_id: data?.id
		}
		dispatch(updateWhoVieWedmed(requestedData))
	}

	setShowData(false);
	dispatch(RemoveAllPatchesId());
	setPatientSignature([null]);
	setShowPatientSignature(true);
	setShowPharmacistSignature(true);
	setPharmacistSignature(null);
	setShowComment(true);
	setComment("");

	setPId(data?.id);
	setTimeout(() => {
		const nextPatientCard = document.getElementById(`patientCard-${index}`);
		if (nextPatientCard) {
			nextPatientCard.scrollIntoView({ behavior: "smooth", block: "start" });
		}
	}, 100);
};
