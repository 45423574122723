export const HOME_ROUTE = '/';
export const VERIFY_DRIVER_PASSCODE = '/verify-driver-passcode';
export const verify_otp = '/verify-otp';

export const SWITCH_PHARMACY = '/switch-pharmacy';

export const DASHBOARD_PHARMACY = '/dashbord-pharmacy';
export const SETTING = '/setting';
export const PENDING_DISPENSE = '/pending-dispense';
export const PHARMACY_FRONT_PROFILE = '/pharmacy-front-profile';

export const DRIVER_PASSWORD = '/driver-password';
export const MY_PROFILE = '/my-profile';
export const DRIVER_PASSCODE = '/driver-passcode';

export const DRIVER_ROUTE_TODAY = '/driver-route-today';
export const DRIVER_ROUTE_DELIVERED = '/delivered-dispense';
export const Patient_Consent = '/patient-consent';
export const Missed_Route = '/driver-today-missed-route';
export const driver_Missed_Route = '/driver-missed-route';

export const search_patient_data = '/e-sign';
export const driver_today_pending = '/driver-today-pending';

export const today_analytics = '/today-analytics';
export const Counselling = '/counselling';

export const upload_prescripition = '/upload-prescripition';

export const pending_pharmacist_signatures = '/pending-signs';


export const change_default_pharmacist = '/change-default-pharmacist';







