import React from 'react';
import Sheet from 'react-modal-sheet';

import { useAllSelector } from '../../services/selectors/allSelector';
import { useLocation } from 'react-router-dom';

import {
	finalMissedRoute,
	todayMissedRoute,
	todayPendingRoute
} from '../../storage';

import { useAuthSelector } from '../../services/selectors/authSelector';

import * as Icons from '../../assets/svg/icons';
import * as routeNames from '../../routes/routeName';
import * as Hooks from '../../hooks';

export const RouteConfirmation = ({ showConfirmationModal, setShowConfirmationModal }) => {

	const navigate = Hooks.useCustomNavigate();
	const location = useLocation()

	const { driverSelectedPharmacy } = useAuthSelector()

	return (
		<React.Fragment>
			<Sheet isOpen={showConfirmationModal} onClose={() => setShowConfirmationModal(false)}>
				<Sheet.Container>
					<Sheet.Content>

						<div className="flex items-center justify-between p-2 px-4 md:p-3 border-b border-dashed rounded-t">
							<h3 className="text-sm font-bold text-gray-900">
								{driverSelectedPharmacy?.name?.toUpperCase()}
							</h3>

							<button type="button"
								onClick={() => setShowConfirmationModal(false)}
								className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 
											ms-auto inline-flex justify-center items-center"
								data-modal-hide="bottom-right-modal">
								<Icons.CloseModelIcon />
								<span className="sr-only">Close modal</span>
							</button>

						</div>
						<div className="max-w-md mx-auto p-6 bg-white  rounded-md">
							<form>

								<div className="mb-4">
									<label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900">
										Are you sure you want to exit from this route?
									</label>
								</div>

								<div className="flex justify-center items-center gap-2">

									{location.pathname.startsWith(routeNames.Missed_Route) ?
										<button onClick={() => {
											return localStorage.removeItem(todayMissedRoute),
												navigate(`${routeNames.DASHBOARD_PHARMACY}`)
										}} type="button" className="bg-blue-800 hover:bg-blue-600 text-white font-semibold px-4 py-2 rounded focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50">
											Yes
										</button> :
										location.pathname.startsWith(routeNames.driver_today_pending) ?
											<button onClick={() => {
												return localStorage.removeItem(todayPendingRoute),
													navigate(`${routeNames.DASHBOARD_PHARMACY}`)
											}} type="button" className="bg-blue-800 hover:bg-blue-600 text-white font-semibold px-4 py-2 rounded focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50">
												Yes
											</button> :
											location.pathname.startsWith(routeNames.driver_Missed_Route) ?
												<button onClick={() => {
													return localStorage.removeItem(finalMissedRoute),
														navigate(`${routeNames.DASHBOARD_PHARMACY}`)
												}} type="button" className="bg-blue-800 hover:bg-blue-600 text-white font-semibold px-4 py-2 rounded focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50">
													Yes
												</button> :

												<button onClick={() => navigate(`${routeNames.DASHBOARD_PHARMACY}`)} type="button" className="bg-blue-800 hover:bg-blue-600 text-white font-semibold px-4 py-2 rounded focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50">
													Yes
												</button>}

									<button onClick={() => setShowConfirmationModal(false)} type="button" className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold px-4 py-2 rounded focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-opacity-50">
										Cancel
									</button>

								</div>
							</form>
						</div>

					</Sheet.Content>
				</Sheet.Container>
				<Sheet.Backdrop />
			</Sheet>
		</React.Fragment>
	)
}

export const MissedDeliveryConfirmation = ({ showConfirmationModal, setShowConfirmationModal, pharmacyId, handleMissedDelivery, missedData }) => {

	const { updateDeliveryListResponse } = useAllSelector()

	return (
		<React.Fragment>
			<Sheet isOpen={showConfirmationModal} onClose={() => setShowConfirmationModal(false)}>
				<Sheet.Container>
					<Sheet.Content >

						<div className="flex items-center justify-between p-2 px-4 md:p-3 border-b border-dashed rounded-t">
							<h3 className="text-sm font-bold text-gray-900">
								{missedData?.first_name} {missedData?.last_name}
							</h3>
							<button type="button"
								onClick={() => setShowConfirmationModal(false)}
								className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
								data-modal-hide="bottom-right-modal">
								<Icons.CloseModelIcon />
								<span className="sr-only">Close modal</span>
							</button>
						</div>
						<div className="max-w-md mx-auto p-6 bg-white  rounded-md">
							<form>
								<div className="mb-4">
									<label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900">
										Are you sure you missed for this delivery ?
									</label>
								</div>

								<div className="flex justify-center items-center gap-2">
									<button onClick={handleMissedDelivery} type="button" className="bg-red-600 hover:bg-red-600 text-white font-semibold px-4 py-2 rounded focus:outline-none 
									focus:ring-2 focus:ring-red-500 focus:ring-opacity-50">
										{/* {updateDeliveryListResponse?.loading ? 'Processing...' : 'Yes'} */}
										Yes
									</button>
									<button onClick={() => setShowConfirmationModal(false)} type="button" className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold px-4 py-2 rounded focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-opacity-50">
										Cancel
									</button>
								</div>
							</form>
						</div>

					</Sheet.Content>
				</Sheet.Container>
				<Sheet.Backdrop />
			</Sheet>
		</React.Fragment>
	)
}
