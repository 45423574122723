import React, {
	useEffect,
	useState,
} from 'react';

import { useAllSelector } from '../../services/selectors/allSelector';
import { useAuthSelector } from '../../services/selectors/authSelector';

import { fetchPharmacistOfPharmacy } from '../../services/apis/dispense/pharmacistOfPharmacy';
import { useLocation } from 'react-router-dom';

import { clearAllTodayDispenseData } from '../../services/slices/dispense/patientForRouteList';
import SearchBox from '../../components/common/searchBox';

import * as Icons from '../../assets/svg/icons';
import * as Common from '../../components/common';
import * as Hooks from '../../hooks';
import * as routeNames from '../../routes/routeName';

const DriverDashboard = () => {

	const navigate = Hooks.useCustomNavigate();
	const dispatch = Hooks.useCustomDispatch();
	const location = useLocation()

	const [apiCallMade, setApiCallMade] = useState(false)

	const { pharmacistOfPharmacyListResponse } = useAllSelector()

	const { pharmacyId, isSupervisor } = useAuthSelector()

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		if (pharmacyId && !apiCallMade && (location?.pathname === routeNames.DASHBOARD_PHARMACY)) {
			dispatch(fetchPharmacistOfPharmacy(pharmacyId))
			dispatch(clearAllTodayDispenseData())
			setApiCallMade(true)
		}
		return () => { }
	}, [])


	const openTodayDispenseModal = () => {
		navigate(routeNames.DRIVER_ROUTE_TODAY)
	}

	return (
		<>
			<div className="app-wrapper flex justify-center h-screen">
				{/* <div className="app-screen no-shades w-full xl:w-3/12 lg:w-4/12 md:w-5/12 sm:w-6/12 h-screen overflow-x-hidden"> */}
				{/* <div className="app-screen no-shades w-full  lg:w-full md:w-6/12 sm:w-6/12 h-full  overflow-x-hidden "> */}

				<div className="app-screen no-shades w-full h-full  overflow-x-hidden ">

					<div className="relative p-4">
						{pharmacistOfPharmacyListResponse?.loading &&
							<div className="progress-loader">
								<div className="progress"></div>
							</div>
						}

						<div className="card bg-white p-4 rounded-lg shadow-sm">
							<div className='flex'><div>
								<Common.TopBar pharmacyId={pharmacyId} />
							</div>
							</div>

							<div className='my-4'>
								<SearchBox pharmacyId={pharmacyId} />
							</div>

							{/* <div className="dashboard-cards-grid grid lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-2 xm:grid-cols-2 gap-3"> */}

							<div className="dashboard-cards-grid grid lg:grid-cols-4 md:grid-cols-4 grid-cols-2 gap-3">

								<div className="card flex flex-col p-3 lg:items-center rounded-md btnnn bg-green-50 gap-3"
									onClick={openTodayDispenseModal}>
									<div className="rounded-md bg-green-700 flex items-center justify-center"
										style={{ width: '40px', height: '40px' }}>
										<Icons.PendingDispenseIcon />
									</div>
									<div className="text-info">
										<h1 className="text-sm font-medium" style={{ fontSize: '13px', fontWeight: '500' }}>Delivery</h1>
									</div>
								</div>

								{isSupervisor === 1 &&
									<div className="card flex lg:items-center flex-col p-3 rounded-md bg-gray-400 bg-opacity-10 gap-3"
										onClick={() => navigate(`${routeNames.today_analytics}`)}>
										<div className="rounded-md bg-gray-700 flex items-center justify-center"
											style={{ width: '40px', height: '40px' }}>
											<Icons.AnalyticsIcon />
										</div>
										<div className="text-info">
											<h1 className="text-sm font-medium" style={{ fontSize: '13px', fontWeight: '500' }}>Supervisor</h1>
										</div>
									</div>}

								<div className="card flex lg:items-center flex-col p-3 rounded-md bg-cyan-400 bg-opacity-10 gap-3"
									onClick={() => navigate(`${routeNames.upload_prescripition}`)} style={{ background: 'rgb(237 241 227 / 72%)' }}>
									<div className="rounded-md bg-cyan-700 flex items-center justify-center"
										style={{ width: '40px', height: '40px', background: '#4d7c0f' }}>
										<Icons.UploadPrescritption />
									</div>
									<div className="text-info">
										<h1 className="text-sm font-medium" style={{ fontSize: '13px', fontWeight: '500' }}>Upload Prescription</h1>
									</div>
								</div>

								<div className="card flex lg:items-center flex-col p-3 rounded-md bg-purple-400 bg-opacity-10 gap-3"
									onClick={() => navigate(`${routeNames.driver_today_pending}`)} >
									<div className="rounded-md bg-purple-700 flex items-center justify-center"
										style={{ width: '40px', height: '40px' }}>
										<Icons.PendingDispenseIcon />
									</div>
									<div className="text-info">
										<h1 className="text-sm font-medium" style={{ fontSize: '13px', fontWeight: '500' }}>Pending Dispense</h1>
									</div>
								</div>


								<div className="card flex lg:items-center flex-col p-3 rounded-md bg-orange-300 bg-opacity-10 gap-3" onClick={() => navigate(`${routeNames.Patient_Consent}`)} >
									<div className="rounded-md flex items-center justify-center"
										style={{ width: '40px', height: '40px', backgroundColor: 'orange' }}>
										<Icons.PatientConsent />
									</div>
									<div className="text-info">
										<h1 className="text-sm font-medium" style={{ fontSize: '13px', fontWeight: '500' }}>Patient Consent</h1>
									</div>
								</div>

								<div className="card flex lg:items-center flex-col p-3 rounded-md bg-blue-50 gap-3" onClick={() => navigate(`${routeNames.PHARMACY_FRONT_PROFILE}`)}>
									<div className="rounded-md bg-blue-700 flex items-center justify-center"
										style={{ width: '40px', height: '40px' }}>
										<Icons.ContactIcon />
									</div>
									<div className="text-info">
										<h1 className="text-sm font-medium" style={{ fontSize: '13px', fontWeight: '500' }}>Contact Pharmacy</h1>
									</div>
								</div>

								<div className="card flex lg:items-center flex-col p-3 rounded-md bg-opacity-10 gap-3" onClick={() => navigate(`${routeNames.MY_PROFILE}`)} style={{ backgroundColor: 'rgb(104 30 71 / var(--tw-bg-opacity))' }}>
									<div className="rounded-md flex items-center justify-center"
										style={{ width: '40px', height: '40px', backgroundColor: 'rgb(104 30 71 )' }}>
										<Icons.ProfileSetting />
									</div>
									<div className="text-info">
										<h1 className="text-sm font-medium" style={{ fontSize: '13px', fontWeight: '500' }}>Profile Settings</h1>
									</div>
								</div>

							</div>
							<Common.BottomBar pharmacyId={pharmacyId} />
						</div>


					</div>
				</div>
			</div>
		</>
	)
}

export default DriverDashboard