import React, { useState, useEffect } from 'react'

import { SuccessMessage } from './AllConfirmationMessages';
import { useCustomDispatch, useCustomLocation } from '../../hooks';
import { updatePatientProfile } from '../../services/apis/setting/updatePatientProfile';
import { useAuthSelector } from '../../services/selectors/authSelector';

import avatar from '../../assets/images/userimg.png';
import * as Icons from '../../assets/svg/icons'
import * as routeName from '../../routes/routeName'

import mapPic from '../../assets/images/google-maps.svg'
import phoneCallPic from '../../assets/images/phone-call.svg'
import contract from '../../assets/images/agreement.svg'


export const displaySelectedImage = (event, setSelectedImage, index, setShowToast, data, dispatch) => {

	if (event.target.files.length > 0) {
		const file = event.target.files[0];

		const requestedData = {
			pharmacy_id: data.pharmacy_id,
			patient_id: data.id,
			profile_image: file
		}
		dispatch(updatePatientProfile(requestedData))

		if (file.type.startsWith('image/')) {
			const reader = new FileReader();

			reader.onload = function (e) {
				setSelectedImage(e.target.result, index);
				setShowToast(prevState => ({ ...prevState, successToast: true }))

				setTimeout(() => {
					setShowToast(prevState => ({ ...prevState, successToast: false }))

				}, 2000);
			};

			reader.readAsDataURL(file);
		}
	}
}

export const PatientDetails = ({ data, totalRxData, index, patientImg }) => {

	const [selectedImage, setSelectedImage] = useState(patientImg || avatar)
	const [showToast, setShowToast] = useState({ successToast: '' })
	const { pharmacyId } = useAuthSelector()

	const dispatch = useCustomDispatch()
	const location = useCustomLocation()

	useEffect(() => {
		if (data?.profile_image != selectedImage) {
			setSelectedImage(avatar)
		}
	}, [selectedImage, data])

	return (
		<React.Fragment>
			<div className="flex items-center gap-2">
				<h2 className="text-lg font-medium uppercase flex justify-center items-center">
					<img src={selectedImage} id={index} alt={`${index}-avtar`} className='rounded-full w-8 h-8 mr-2'
						onClick={() => {
							const filePicker = document.getElementById(`selectProfileImage-${index}`);
							filePicker.click()
						}}
					/>
					{pharmacyId === 1502 ? <>XXXXX XX{data?.last_name.slice(-3)}</> : <>{data?.first_name} {data?.last_name}</>}</h2>
					{/* {data?.first_name} {data?.last_name}</h2> */}

				<span className="flex items-center justify-center h-5 w-5 text-sm rounded-full bg-green-600 text-white">
					{totalRxData}</span>
				<input name='avatar' accept="image/jpeg, image/jpg, image/png,capture=camera"
					onChange={(event) => displaySelectedImage(event, setSelectedImage, index, setShowToast, data, dispatch)} type='file' id={`selectProfileImage-${index}`} className='hidden' />

			</div>

			{!data?.phone ? <p className="text-xs text-gray-400 my-1 break-all" style={{ fontSize: '10px' }}>PHN: {pharmacyId === 1502 ? `XXXXX XX${data?.phn.slice(-3)}` : data?.phn}
				{data?.dob && <> | DOB: {pharmacyId === 1502 ? 'XX XX XXXX' : data?.dob} </>} {data?.gender && <> | Gender: {pharmacyId === 1502 ? 'XX' : data?.gender}</>}
			</p>
				: <>
					<p className="text-xs text-gray-400 my-1 break-all" style={{ fontSize: '10px' }}>PHN: {pharmacyId === 1502 ? `XXXXX XX${data?.phn.slice(-3)}` : data?.phn}
						{data.phone && <> | PH: {pharmacyId === 1502 ? `XXXXX XX${data?.phone.slice(-3)}` : data?.phone}  </>} {data?.dob && <> | DOB: {pharmacyId === 1502 ? 'XX XX XXXX' : data?.dob} </>}
					</p>

					<p className="text-xs text-gray-400 my-1 break-all" style={{ fontSize: '10px' }}>
						{data?.gender && <> Gender: {pharmacyId === 1502 ? 'XX' : data?.gender}</>}
					</p>
				</>}

			{location.pathname === routeName.DRIVER_ROUTE_DELIVERED &&
				<p className='text-xs text-gray-400 my-1 break-all'>{data?.delivery_human_time}</p>}

			<div className="flex gap-2 mt-3">
				<Icons.LocationIcon />
				<p className="text-gray-500">{pharmacyId === 1502 ? 'XXXXX XXXXX XXXXX XXXXX ' : data?.full_address}</p>
			</div>

			{/* {
				data?.delivey_note &&
				<div className="flex gap-2 -mb-2">
					<p className="text-red-500 text-sm" style={{ fontWeight: '400' }}>Note: {data?.delivey_note}</p>
				</div>
			} */}

			{data?.delivey_note && <p className='mt-2 bg-fuchsia-200 p-1 pl-2 rounded-md' style={{ color: 'black', fontSize: '13px', fontWeight: 'bold', backgroundColor: 'rgb(132 204 22)' }}>
				<span className='text-red-700'>Note: </span>{data?.delivey_note}
			</p>}

			{showToast.successToast &&
				<label className='fixed -left-3 -right-0 bottom-0 z-50'>
					<SuccessMessage message={'Profile Updated Successfully.'} setShowToast={() => setShowToast(prevState => ({ ...prevState, successToast: false }))} />
				</label>
			}


		</React.Fragment >)
}

export const PatientMobileAndLocation = ({ data, setIsTransferRequest, isTransferRequest, index }) => {

	const { driverSelectedPharmacy } = useAuthSelector()
	const location = useCustomLocation()

	return (
		<React.Fragment>
			<div className="flex items-center gap-3">
				<button className="px-2 py-1.5 rounded-md">
					<a href={`tel:${data?.phone}`}>
						<img src={phoneCallPic} alt='phone-call-Pic' className='w-6 h-6' />
					</a>
				</button>
				<button className="px-1 py-1.5 rounded-md bg-opacity-10">
					<a target='_blank' href={data?.map_url}>
						<img src={mapPic} alt='mapPic' className='w-6 h-6' />
					</a>
				</button>

				{driverSelectedPharmacy?.transfer_concent === 1 ?
					location.pathname === routeName.DRIVER_ROUTE_DELIVERED || location.pathname === routeName.pending_pharmacist_signatures || location.pathname === routeName.Counselling ? null :
						data?.pharmacy_transfer_concents === null ?
							// <button onClick={handleToggleTransferConsent} className="px-1 py-1.5 rounded-md bg-red-600 bg-opacity-10">
							// 	<a>
							// 		<Icons.isTransferIcon />
							// 	</a>
							// </button>
							null
							:
							<button className="px-1 py-1.5 rounded-md  bg-opacity-10">
								<a>
									<img src={contract} alt='mapPic' className='w-6 h-6' />
								</a>
							</button>
					: null
				}

				{/* <Sheet isOpen={showModal} onClose={() => setShowModal(false)}>
					<Sheet.Container>
						<Sheet.Content>
							<div className="flex items-center justify-between p-2 px-4 md:p-3 border-b border-dashed rounded-t">
								<h3 className="text-sm font-bold text-gray-900">
									Consent Agreement
								</h3>
								<button type="button" onClick={() => setShowModal(false)}
									className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
									data-modal-hide="bottom-right-modal">
									<Icons.CloseModelIcon />
									<span className="sr-only">Close modal</span>
								</button>
							</div>
							<div className="p-4 md:p-5 space-y-4">
								<form>
									<div className="flex items-top mb-4">
										<input id={`${index}-default-checkbox`} defaultChecked={isTransferRequest} onChange={(e) => { return setIsTransferRequest(e.target.checked), setShowModal(false) }} type="checkbox" value="" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
										<label htmlFor={`${index}-default-checkbox`} className="ms-2 ml-2 text-sm text-gray-900 dark:text-gray-300">
											I,
											<span className='text-sm font-semibold text-gray-900'> {data?.first_name} {data?.last_name}</span>, authorize
											<span className='text-sm font-semibold text-gray-900'> {driverSelectedPharmacy?.pharmacies?.name?.toUpperCase()} </span>
											to transfer my medications from another pharmacy, enabling them to manage all my medication-related care for easier coordination.
										</label>
									</div>

									<div className='text-center'>
										<button style={{ background: 'orange' }} className='text-center text-white text-sm p-1 rounded-md'>
											<a href={samplePdF} target='_blank'>
												Sample PDF
											</a>
										</button>
									</div>
								</form>
							</div>
						</Sheet.Content>
					</Sheet.Container>
					<Sheet.Backdrop />
				</Sheet> */}
			</div>
		</React.Fragment>
	)
}

export const TransferConsentMessage = ({ data }) => {

	const location = useCustomLocation()

	return (
		<React.Fragment>
			{/* {location.pathname === routeName.DRIVER_ROUTE_DELIVERED ? null :
				data?.pharmacy_transfer_concents !== null ?
					<div className='bg-red-500 pl-2 text-white text-sm p-0.5 rounded-md mt-2'>
						<p>Transfer Consent Submission already Done.</p>
					</div>
					: null
			} */}
		</React.Fragment>
	)
}


