import './App.css';
import React, { useEffect, useState } from 'react';
import './assets/css/style.css'

import { isReduxStoredCleared } from './storage';
import { useCustomDispatch, useCustomLocation, useCustomNavigate } from './hooks';

import { useAuthSelector } from './services/selectors/authSelector';
import { authToken } from './storage/authToken';
import { fetchPharmacyDetails } from './services/apis/dispense/getPharmacyDetail';

import AppRoutes from './routes/appRoutes';
import Cookies from 'js-cookie';

import Ably from 'ably';
import * as routeNames from './routes/routeName';
import * as EndPoints from './networking/urlEndPoints'

function App() {

	const { pharmacyId } = useAuthSelector()

	const location = useCustomLocation();
	const navigate = useCustomNavigate();
	const dispatch = useCustomDispatch();

	const token = authToken()

	useEffect(() => {
		if (isReduxStoredCleared) {
			Cookies.remove('authToken')
			navigate(routeNames.HOME_ROUTE)
		}
	}, [])

	const [isOnline, setIsOnline] = useState(window.navigator.onLine);

	useEffect(() => {
		const handleOnlineStatusChange = () => {
			setIsOnline(window.navigator.onLine);
		};

		window.addEventListener('online', handleOnlineStatusChange);
		window.addEventListener('offline', handleOnlineStatusChange);

		return () => {
			window.removeEventListener('online', handleOnlineStatusChange);
			window.removeEventListener('offline', handleOnlineStatusChange);
		};
	}, []);

	useEffect(() => {

		const ably = new Ably.Realtime({ key: EndPoints.apiKey });
		const channel = ably.channels.get('oat-pharmacy-inspection-mode');

		channel.subscribe(`inspection-${pharmacyId}`, (message) => {
			if (message.name === `inspection-${pharmacyId}` && pharmacyId && token) {
				dispatch(fetchPharmacyDetails(pharmacyId))
			}
		})

		return () => {
			if (ably.connection.state === 'connected') {
				ably.close();
			}
		}
	}, [pharmacyId]);

	useEffect(() => {
		window.addEventListener('scroll', () => {
			const metaTag = document.querySelector('meta[name="theme-color"]');
			if (window.scrollY / window.innerHeight > 0.8) {
				metaTag.setAttribute("content", "#FDF8F7");
			} else {
				metaTag.setAttribute("content", "#FDF8F7");
			}
		}, false)
	}, [pharmacyId])

	
	return (
		<>
			{!isOnline && (
				<div className="fixed inset-0 flex items-center justify-center z-50 backdrop-filter backdrop-blur-sm">
					<div className="bg-white p-6 rounded-lg shadow-lg">
						<div className="flex items-center mb-4">
							<svg className="w-6 h-6 mr-3 text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
								<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
							</svg>
							<p className="font-semibold text-lg">Connection Lost</p>
						</div>
						<p>Please check your internet connection.</p>
					</div>
				</div>)}

			{/* <div className="app-wrapper flex justify-center h-screen">
				<div className={`app-screen w-full ${location.pathname === '/' ? '' : ' no-shades'} lg:w-full md:w-6/12   sm:w-6/12 h-full overflow-x-hidden`}> */}
					<AppRoutes />
				{/* </div>
			</div> */}

		</>
	);
}

export default App;
