import { createSlice } from "@reduxjs/toolkit";
import { authSelectedPharmacy } from "../../apis/auth/authSelectedPharmacy";

const initialState = {
	loading: false,
	data: null,
	error: null
}

const authSelectedPharmacySlice = createSlice({
	name: 'authSelectedPharmacy',
	initialState,

	extraReducers: (builder) => {
		builder.addCase(authSelectedPharmacy.pending.type, (state) => {
			return {
				...state,
				loading: true,
				data: null,
				error: null
			}
		})

		builder.addCase(authSelectedPharmacy.fulfilled.type, (state, action) => {
			return {
				...state,
				loading: false,
				data: action.payload,
				error: null
			}
		})

		builder.addCase(authSelectedPharmacy.rejected.type, (state, action) => {
			return {
				...state,
				loading: false,
				data: null,
			}
		})
	}

})

export default authSelectedPharmacySlice.reducer