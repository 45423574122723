import React, { useEffect } from 'react';

import {
	DeliverSuccessfull,
	DeliveryDoneByAnotherDriver,
	DeliveryErrorMessages,
	MissedDeliveryErrorMessages,
} from '../../components/common/AllConfirmationMessages';

import {
	PatientDetails,
	PatientMobileAndLocation,
	TransferConsentMessage
} from '../../components/common/patientDetails';

import {
	onhandleConfirmDelivery,
	onhandleMissedDelivery
} from '../../utils/helper/deliveriesHelper';

import {
	MissedDeliveryConfirmation,
	RouteConfirmation
} from '../../components/common/routeConfirmation';

import {
	apiKey,
	deliveryDriverDeliveredDispense
} from '../../networking/urlEndPoints';

import {
	clearAllTodayDispenseData,
	removeTodayDispenePatientById,
	todayDispenseRemovingPageNumber
} from '../../services/slices/dispense/patientForRouteList';

import { fetchPatientlistOnBasisOfRoute } from '../../services/apis/dispense/patientsForRouteLIst';
import { useAllSelector } from '../../services/selectors/allSelector';

import { LoadingView } from '../../components/common/loader';
import { ErrorMessages } from '../../components/error/errorMessages';

import { DispenseRxData } from '../../components/common/dispenseRxData';
import { handleToggleRXData } from '../../utils/helper/helper';
import { useCustomStates } from '../../hooks/useCustomStates';

import { useAuthSelector } from '../../services/selectors/authSelector';
import { RoutePharmacistModal } from '../../components/common/routePharmacistModal';

import { RemoveAllPatchesId } from '../../services/slices/dispense/validateReturnPatches';

import { clearSelectedPharmacistID } from '../../services/slices/auth/selectedPharmacist';
import { TodayDispenseDeliveredByAnotherDriverAblyHelper } from '../../utils/helper/ablyDeliveredDispenseByOthers';
import { PharmacistSelection } from '../../components/common/pharmacistSelection';

import InfiniteScroll from 'react-infinite-scroll-component';
import SearchBox from '../../components/common/searchBox';

import * as Icons from '../../assets/svg/icons';
import * as Common from '../../components/common';
import * as Hooks from '../../hooks';
import * as routeName from '../../routes/routeName';

const TodayDispense = () => {

	const location = Hooks.useCustomLocation()
	const dispatch = Hooks.useCustomDispatch();
	const navigate = Hooks.useCustomNavigate();

	const {
		pharmacyId,
		selectedPharmacistID,
		driverID,
		pharmacyInspectionMode,
		instoreDefaultRoute } = useAuthSelector()


	const {
		patientDataBasisOfRouteResponse,
		todaysDispenseList,
		validateReturnedPatchesResponse,
		routeListResponse
	} = useAllSelector();


	const { comment,
		setComment,

		cordinate,
		setCordinate,

		openIndex,
		setOpenIndex,

		isRefused,
		setIsRefused,

		patientSignature,
		setPatientSignature,

		pharmacistSignature,
		setPharmacistSignature,

		showErrorToast,
		setShowErrorToast,

		loading,
		setLoading,

		showSuccessToast,
		setShowSuccessToast,

		showComment,
		setShowComment,

		showPatientSignature,
		setShowPatientSignature,

		showPharmacistSignature,
		setShowPharmacistSignature,

		showData,
		setShowData,

		apiCallMade,
		setApiCallMade,

		showToast,
		setShowToast,

		showConfirmationModal,
		setShowConfirmationModal,

		setMissedDeliveryErrorToast,
		missedDeliverErrorToast,

		missedDeliveryConfirmation,
		setMissedDeliveryConfirmation,

		missedData,
		setMissedData,

		signatureErrors,
		setSignatureErrors,

		covid19,
		setCovid19,

		selectedRoute,
		setSelectedRoute,

		isTransferRequest,
		setIsTransferRequest,

		loadMore,
		setLoadMore,

		delveryDoneByAnotherDriver,
		setDeliveryDoneByAnotherDriver,

		missedIndex,
		setMissedIndex,

		patientId,
		setPatientID,

		pId,
		setPId,

		showModel,
		setShowModel

	} = useCustomStates()

	// ----------------------------#DropDown RX DATA----------------

	const handleDropdownToggle = (index, data) => {
		handleToggleRXData(
			index,
			data,
			openIndex,
			setOpenIndex,
			dispatch,
			clearSelectedPharmacistID,
			setSignatureErrors,
			setShowData,
			RemoveAllPatchesId,
			setPatientSignature,
			setShowComment,
			setShowPatientSignature,
			setShowPharmacistSignature,
			setPharmacistSignature,
			setComment,
			setPId,
			pharmacyId
		);
	};

	// ---------------------------Pickup BY-DEFAULT Route----------------

	useEffect(() => {
		if (instoreDefaultRoute && pharmacyId) {
			const params = {
				route: instoreDefaultRoute,
				pharmacy_id: pharmacyId,
				setLoading: setLoading,
			}
			if (!apiCallMade) {
				dispatch(clearAllTodayDispenseData())
				dispatch(todayDispenseRemovingPageNumber())
				setLoading(true)
				dispatch(fetchPatientlistOnBasisOfRoute(params))
				setApiCallMade(true)
			}
		}

		return () => { }
	}, [])

	useEffect(() => {
		if (routeListResponse?.includes(instoreDefaultRoute)) {
			setSelectedRoute(instoreDefaultRoute)
		} else {
			setSelectedRoute('')
		}
		return () => { }
	}, [instoreDefaultRoute, routeListResponse])

	// ---------------------------Pickup BYDEfault Route----------------

	const handleConfirmDelivery = (patientData, index) => {
		onhandleConfirmDelivery(patientData,
			index,
			pharmacyId,
			selectedPharmacistID,
			patientSignature,
			pharmacistSignature,
			cordinate,
			comment,
			dispatch,
			setLoading,
			setOpenIndex,
			setShowErrorToast,
			setShowSuccessToast,
			pharmacyInspectionMode,
			isRefused,
			covid19,
			'today_dispense',
			setIsRefused,
			setSignatureErrors,
			validateReturnedPatchesResponse,
			isTransferRequest,
			setIsTransferRequest,
		)
	}

	const handleMissedDelivery = () => {
		onhandleMissedDelivery(pharmacyId,
			missedData,
			missedIndex,
			dispatch,
			setOpenIndex,
			setMissedDeliveryErrorToast,
			setMissedDeliveryConfirmation,
			"today_missed",
			"today_dispense")
	}

	const handleRouteChange = async (e) => {
		const value = e.target.value

		if (value) {
			setSelectedRoute(value);
			setShowToast(true)

			setTimeout(() => {
				setShowToast(false)
			}, 2000);
		}

		const params = {
			route: value,
			pharmacy_id: pharmacyId,
		}

		if (value) {
			dispatch(clearAllTodayDispenseData())
			dispatch(todayDispenseRemovingPageNumber())
			dispatch(fetchPatientlistOnBasisOfRoute(params));
		}
	}

	const fetchMoreData = () => {
		if (patientDataBasisOfRouteResponse?.currentPage < patientDataBasisOfRouteResponse?.totalPages) {
			setLoadMore(true)
			if (selectedRoute && pharmacyId) {
				const params = {
					route: selectedRoute,
					pharmacy_id: pharmacyId,
					page: patientDataBasisOfRouteResponse?.currentPage + 1,
					setLoadMore: setLoadMore
				}
				setLoading(false)
				dispatch(fetchPatientlistOnBasisOfRoute(params))
			}
		}
	}

	return (
		<>
			{patientDataBasisOfRouteResponse?.data?.length > 0 &&
				<InfiniteScroll
					dataLength={patientDataBasisOfRouteResponse?.data?.length}
					next={fetchMoreData}
					hasMore={patientDataBasisOfRouteResponse?.currentPage <= patientDataBasisOfRouteResponse?.totalPages}
				/>
			}

			<div className="app-wrapper flex justify-center">
				{/* <div className="app-screen no-shades w-full xl:w-3/12 lg:w-4/12 md:w-5/12 sm:w-6/12 h-full overflow-x-hidden "> */}
				{/* <div className="app-screen no-shades w-full  lg:w-full md:w-6/12 sm:w-6/12 h-full  overflow-x-hidden "> */}
				<div className="app-screen no-shades w-full h-full  overflow-x-hidden ">

					<div className="relative p-4">

						<div className="card bg-white p-4 rounded-lg shadow-sm">
							<div className="flex items-center justify-between gap-3">

								<div>
									<Common.TopBar pharmacyId={pharmacyId} />
								</div>

								<div className='flex items-center justify-center btnnn pt-4' onClick={() => navigate(routeName.DASHBOARD_PHARMACY)} >
									<Icons.HomeIcon />
								</div>

							</div>
						</div>

						<div className="flex gap-3 mb-3">
							<SearchBox pharmacyId={pharmacyId} />
						</div>

						<RoutePharmacistModal
							handleRouteChange={handleRouteChange}
							showModel={showModel}
							setShowModel={setShowModel}
							selectedRoute={selectedRoute}
							placeholder='Select Route'
							isDelivered={false} route={selectedRoute} />

						<div className="routes-list">

							{(patientDataBasisOfRouteResponse?.loading) ?

								<div className='flex justify-center pb-4 h-96'>
									<LoadingView />
								</div>

								: !selectedRoute ?

									<p className="flex justify-center pb-4 h-96 text-gray-500">
										Oops! It looks like no route was found. Please select a route.
									</p> :

									patientDataBasisOfRouteResponse?.error !== null ?

										<div className='flex items-center justify-center pb-4 mb-4 h-96'>
											<ErrorMessages error={'Oops! Got an issue while fetching Data.Please try again!'} />
										</div> :

										(todaysDispenseList?.length === 0 && !patientDataBasisOfRouteResponse?.loading) ?

											<p className='flex justify-center pb-4 h-96'>
												All Deliveries Done for this Route.
											</p> :

											todaysDispenseList?.map((data, index) => (

												<React.Fragment key={index}>
													<div className="card bg-white p-4 rounded-lg shadow-sm mb-3" id={`patientCard-${index}`}>

														<PatientDetails
															data={data}
															patientImg={data.profile_image}
															totalRxData={data?.patients_win_rx_data?.length}
															index={index} />

														<div className="flex items-center justify-between mt-4">
															<PatientMobileAndLocation data={data}
																setIsTransferRequest={setIsTransferRequest}
																isTransferRequest={isTransferRequest}
																index={index}
															/>

															<button className="p-2.5 rounded-md bg-gray-100" onClick={() => handleDropdownToggle(index, data)}>
																{openIndex === index ?
																	<Icons.DropdownBoxIcon2 /> :
																	<Icons.DropdownBoxIcon1 />}
															</button>
														</div>

														<TransferConsentMessage data={data} />

														{openIndex === index &&
															<>
																<DispenseRxData
																	rxData={data?.patients_win_rx_data}
																	patientData={data} />

																{patientId !== data?.id ? <>

																	<PharmacistSelection
																		signatureErrors={signatureErrors}
																		setSignatureErrors={setSignatureErrors}
																		setShowData={setShowData}
																	/>

																	{showData &&
																		<React.Fragment>
																			<div className='grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-2'>
																				<div>
																					<Common.PatientSignature patientSignature={patientSignature}
																						setPatientSignature={setPatientSignature}
																						showPatientSignature={showPatientSignature}
																						setShowPatientSignature={setShowPatientSignature}
																						isRefused={isRefused}
																						setIsRefused={setIsRefused}
																						signatureErrors={signatureErrors}
																						setSignatureErrors={setSignatureErrors}
																						covid19={covid19}
																						setCovid19={setCovid19}
																					/>
																				</div>

																				<div>
																					<Common.PharmacistSignature
																						pharmacistSignature={pharmacistSignature}
																						setPharmacistSignature={setPharmacistSignature}
																						showPharmacistSignature={showPharmacistSignature}
																						setShowPharmacistSignature={setShowPharmacistSignature}
																						signatureErrors={signatureErrors}
																						setSignatureErrors={setSignatureErrors}
																					/>
																				</div>

																				<div>
																					<Common.Comment showComment={showComment}
																						setShowComment={setShowComment}
																						setComment={setComment} comment={comment} />
																				</div>
																			</div>
																		</React.Fragment>
																	}

																	<div className="flex items-center gap-3">

																		{showData ?
																			validateReturnedPatchesResponse?.loading ? null :
																				<button className="px-4 flex-1 py-2 bg-green-500 text-white rounded-md"
																					onClick={() => handleConfirmDelivery(data, index)}>
																					Continue
																				</button>
																			:
																			null
																		}

																	</div> </> : null}
															</>
														}
													</div>
												</React.Fragment>))}
						</div>

						{loadMore &&
							<div className='flex mb-2'>
								<div className="loaderss"></div>
							</div>}

					</div>
				</div>
			</div>

			{showErrorToast && <DeliveryErrorMessages setShowErrorToast={setShowErrorToast} />}
			{showSuccessToast && <DeliverSuccessfull setShowSuccessToast={setShowSuccessToast} />}
			{missedDeliverErrorToast && <MissedDeliveryErrorMessages setMissedDeliveryErrorToast={setMissedDeliveryErrorToast} />}
			{delveryDoneByAnotherDriver && <DeliveryDoneByAnotherDriver setMissedDeliveryErrorToast={setDeliveryDoneByAnotherDriver} />}


			{showConfirmationModal &&
				<RouteConfirmation setShowConfirmationModal={setShowConfirmationModal}
					showConfirmationModal={showConfirmationModal}
					pharmacyId={pharmacyId} />
			}


			{missedDeliveryConfirmation &&
				<MissedDeliveryConfirmation
					setShowConfirmationModal={setMissedDeliveryConfirmation}
					showConfirmationModal={missedDeliveryConfirmation}
					pharmacyId={pharmacyId}
					handleMissedDelivery={handleMissedDelivery}
					missedData={missedData} />}


			<TodayDispenseDeliveredByAnotherDriverAblyHelper
				apiKey={apiKey}
				deliveryDriverDeliveredDispense={deliveryDriverDeliveredDispense}
				pharmacyId={pharmacyId}
				driverID={driverID}
				pId={pId}
				routeName={routeName}
				location={location}
				setPatientID={setPatientID}
				setDeliveryDoneByAnotherDriver={setDeliveryDoneByAnotherDriver}
				dispatch={dispatch}
				removeTodayDispenePatientById={removeTodayDispenePatientById}
				setOpenIndex={setOpenIndex}
				setPId={setPId}
				setCordinate={setCordinate}
			/>

		</>
	)
}

export default TodayDispense
