import Cookies from "js-cookie";
import Axios from "../../../networking/interceptor";

import { createAsyncThunk } from "@reduxjs/toolkit";
import { oat_driver_login } from "../../../networking/urlEndPoints";
import { authSelectedPharmacy } from "./authSelectedPharmacy";

import { fetchPharmacyDetails } from "../dispense/getPharmacyDetail";
import { setDriverDefaultRoute } from "../../slices/auth/driverDefaultRoute";

import * as routeNames from '../../../routes/routeName';
import * as Storage from '../../../storage'

export const authLogin = createAsyncThunk('authLogin', async (userDetail, { rejectWithValue }) => {

	try {
		const requesteData = {
			email: userDetail?.email,
			password: userDetail?.password,
			passcode: userDetail?.passcode
		}

		const response = await Axios.post(oat_driver_login, requesteData);
		const data = response.data;

		Cookies.set('authToken', data?.data?.token)

		if (typeof userDetail.navigate === 'function') {

			if (data?.data?.driver_pharmacy) {
				userDetail.dispatch(authSelectedPharmacy(data?.data?.driver_pharmacy))
				userDetail.dispatch(fetchPharmacyDetails(data?.data?.driver_pharmacy?.id))
				userDetail.dispatch(setDriverDefaultRoute(data?.data?.driver_default_route))
				userDetail.navigate(`${routeNames.DASHBOARD_PHARMACY}`)
				localStorage.removeItem(Storage.todayPendingRoute)
			}

		}

		return data;

	} catch (error) {
		return rejectWithValue(error.response)
	}

})