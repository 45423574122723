import React, {
	useState,
	useEffect
} from 'react'

import { useAuthSelector } from '../../services/selectors/authSelector';

export const TopBar = () => {

	const { driverSelectedPharmacy, pharmacyId } = useAuthSelector()

	const getCurrentTimeCanada = () => {
		const currentTime = new Date();

		const options = {
			timeZone: 'America/Vancouver',
			hour12: true,
			hour: 'numeric',
			minute: 'numeric',
			second: 'numeric',
			year: 'numeric',
			month: 'short',
			day: 'numeric',
		};

		const canadaTime = currentTime.toLocaleString('en-US', options);
		return canadaTime;
	}

	const DisplayCurrentTime = () => {
		const currentCanadaTime = getCurrentTimeCanada();
		setCurrentDateAndTime(currentCanadaTime);
	}
	const [currentDateAndTime, setCurrentDateAndTime] = useState(getCurrentTimeCanada);

	useEffect(() => {
		const interval = setInterval(DisplayCurrentTime, 1000);

		return () => clearInterval(interval);
	}, []);

	return (
		<>
			<div className="flex items-center gap-3">
				<div className="rounded-md flex items-center justify-center" style={{ width: '60px', height: '60px' }}>
					<img className='rounded-lg' src={driverSelectedPharmacy?.logo_thumb_full_url} />
				</div>

				<div className="text-info">
					<h1 className="text-md font-bold">{driverSelectedPharmacy?.name.toUpperCase()}</h1>
					<p className="text-sm text-gray-500">{driverSelectedPharmacy?.city}, {driverSelectedPharmacy?.country}</p>
					<p className="text-sm text-gray-500">{currentDateAndTime}</p>
				</div>
			</div>
		</>
	)
}


