import React from 'react';

import { Route, Routes } from 'react-router-dom';
import { authToken } from '../storage/authToken';
import { ToastContainer } from 'react-toastify';
import { useAuthSelector } from '../services/selectors/authSelector';

import * as Auth from '../auth';
import * as Pages from '../pages';
import * as routeNames from './routeName';

import ProtectedRoute from './protectedRoute';

const AppRoutes = () => {

	const token = authToken();

	const { pharmacyId, isSupervisor } = useAuthSelector()

	return (

		<>
			<Routes>
				{!token && <>
					<Route exact path={routeNames.HOME_ROUTE} element={<Auth.SignIn />} />
				</>}

				{pharmacyId && token ? <>
					<Route exact path={`${routeNames.DASHBOARD_PHARMACY}`} element={<ProtectedRoute Component={Pages.DriverDashboard}></ProtectedRoute>} />
					<Route exact path={`${routeNames.DRIVER_ROUTE_TODAY}`} element={<ProtectedRoute Component={Pages.TodayDispense}></ProtectedRoute>} />

					<Route exact path={`${routeNames.PHARMACY_FRONT_PROFILE}`} element={<ProtectedRoute Component={Pages.PharmacyFrontProfile}></ProtectedRoute>} />

					<Route exact path={routeNames.DRIVER_PASSWORD} element={<ProtectedRoute Component={Pages.ChangePassword}></ProtectedRoute>} />
					<Route exact path={`${routeNames.MY_PROFILE}`} element={<ProtectedRoute Component={Pages.MyProfile}></ProtectedRoute>} />
					<Route exact path={routeNames.DRIVER_PASSCODE} element={<ProtectedRoute Component={Pages.UpdatePasscode}></ProtectedRoute>} />

					<Route exact path={`${routeNames.Patient_Consent}`} element={<ProtectedRoute Component={Pages.PatientConsent}></ProtectedRoute>} />
					{/* <Route exact path={`${routeNames.Missed_Route}`} element={<ProtectedRoute Component={Pages.MissedDeliveryPatient}></ProtectedRoute>} />
					<Route exact path={`${routeNames.driver_Missed_Route}`} element={<ProtectedRoute Component={Pages.FinalMissedDelivery}></ProtectedRoute>} /> */}

					<Route exact path={`${routeNames.DRIVER_ROUTE_DELIVERED}`} element={<ProtectedRoute Component={Pages.DeliveredPatient}></ProtectedRoute>} />
					<Route exact path={`${routeNames.driver_today_pending}`} element={<ProtectedRoute Component={Pages.PendingDispense}></ProtectedRoute>} />
					<Route exact path={`${routeNames.search_patient_data}`} element={<ProtectedRoute Component={Pages.SearchPatientList}></ProtectedRoute>} />

					<Route exact path={`${routeNames.upload_prescripition}`} element={<ProtectedRoute Component={Pages.UploadPrescripition}></ProtectedRoute>} />

					{isSupervisor === 1 && <>
						<Route exact path={`${routeNames.today_analytics}`} element={<ProtectedRoute Component={Pages.TodaysAnalytics}></ProtectedRoute>} />
						<Route exact path={`${routeNames.Counselling}`} element={<ProtectedRoute Component={Pages.Counselling}></ProtectedRoute>} />
						<Route exact path={`${routeNames.pending_pharmacist_signatures}`} element={<ProtectedRoute Component={Pages.PendingPharmacistSignatures}></ProtectedRoute>} />
					</>}

				</> :
					<Route path="*" element={<Auth.SignIn />} />
				}

				{token && pharmacyId ?
					<Route path="*" element={<Pages.DriverDashboard />} />
					:
					<Route path="*" element={<Auth.SignIn />} />}

			</Routes>
			<ToastContainer />
		</>
	)
}

export default AppRoutes