import React from 'react';
import * as Icons from '../../assets/svg/icons';

export const Comment = ({ setComment, showComment, setShowComment, comment }) => {

	return (
		<>
			<div className="p-4  bg-gray-100 rounded-md mb-3">
				<div className="collapse-header flex items-center justify-between" onClick={() => setShowComment(!showComment)}>
					<h2>Comment</h2>
					{showComment ?
						<Icons.CommentIcon2 /> :
						<Icons.CommentIcon1 />}
				</div>
				{showComment &&
					<div className="mt-3">
						<textarea type="text" id="comment" value={comment} onChange={(e) => setComment(e.target.value)}
							className="block w-full lg:p-1 p-4 lg:h-56 text-gray-900 border border-gray-300 outline-0 rounded-lg bg-white focus:border-primary "
							placeholder="Type Here" required style={{ fontSize: '14px' }} />
					</div>}

				<div className="lg:pt-3">

				</div>
			</div>

		</>
	)
}
