import React, { useEffect, useRef, useState } from 'react';
import { useAuthSelector } from '../../services/selectors/authSelector';

import SignatureCanvas from 'react-signature-canvas';
import * as Icons from '../../assets/svg/icons';

export const PatientSignature = ({
	patientSignature,
	setPatientSignature,
	showPatientSignature,
	setShowPatientSignature,
	isRefused,
	setIsRefused,
	signatureErrors,
	setSignatureErrors,
	covid19,
	setCovid19
}) => {

	const canvasRefs = useRef([])

	const [showSign, setShowSign] = useState(false);
	const { driverSelectedPharmacy, pharmacyInspectionMode } = useAuthSelector();

	const handleClearPatientSignature = (index) => {
		const canvasRef = canvasRefs.current[index];
		if (canvasRef) {
			if (!showSign) {
				canvasRef.clear();
				const newSignatures = [...patientSignature];
				newSignatures[index] = null;
				setPatientSignature(newSignatures);
			}
		}
	};

	const handleAddPatientSignature = () => {
		if (patientSignature?.length < 5) {
			setPatientSignature(prevSignatures => [...prevSignatures, null]);
		} else {
		}
	};

	const handlePatientSignatureEnd = (index) => {
		const signatureDataURL = canvasRefs.current[index]?.toDataURL();
		const newSignatures = [...patientSignature];
		newSignatures[index] = signatureDataURL;
		setPatientSignature(newSignatures);
		setShowSign(false);
		// setSignatureErrors({ ...signatureErrors, patientSign: null })
	};


	const handleRemovePatientSignature = (index) => {
		const newSignatures = patientSignature?.filter((_, i) => i !== index);
		setPatientSignature(newSignatures);

		const canvasRef = canvasRefs.current[index];
		if (canvasRef) {
			canvasRef.clear();
		}

		canvasRefs.current = canvasRefs?.current.filter((_, i) => i !== index);

		canvasRefs?.current?.forEach((ref, i) => {
			if (i === index && ref) {
				ref.clear();
			} else {
				if (newSignatures && ref) {
					ref.fromDataURL(newSignatures);
				}
			}
		});
	};

	const handleToggleSignatures = () => {
		setShowSign((prevState) => !prevState);
		setShowPatientSignature((prevState) => !prevState);
		setTimeout(() => {
			if (patientSignature) {
				patientSignature?.forEach((signature, index) => {
					if (signature) {
						const canvasRef = canvasRefs.current[index];
						if (canvasRef) {
							canvasRef.fromDataURL(signature);
						}
					}
				});
			}
		}, 0);
	};

	useEffect(() => {
		setTimeout(() => {
			if (patientSignature) {
				patientSignature?.forEach((signature, index) => {
					if (signature) {
						const canvasRef = canvasRefs.current[index];
						if (canvasRef) {
							canvasRef.fromDataURL(signature);
						}
					}
				});
			}
		}, 0);
	}, [patientSignature])

	useEffect(() => {
		if (patientSignature[0] !== null) {
			setSignatureErrors({ ...signatureErrors, patientSign: null })
		}
	}, [patientSignature])

	return (
		<div className="p-4  bg-gray-100 rounded-md mb-3" style={{ border: signatureErrors.patientSign }}>
			<div className="collapse-header flex items-center justify-between" onClick={handleToggleSignatures}>
				<h2>Patient Signature</h2>
				{showPatientSignature ? <Icons.PatientSignatureIcon2 /> : <Icons.PatientSignatureIcon1 />}
			</div>

			{showPatientSignature && (
				<React.Fragment>
					{patientSignature?.map((signature, index) => (
						<div key={index} className="mt-3">
							<div className="w-full relative flex items-center justify-center rounded-md">
								<SignatureCanvas
									penColor="black"
									clearOnResize={false}
									canvasProps={{ className: 'signature' }}
									ref={(ref) => (canvasRefs.current[index] = ref)}
									onEnd={() => handlePatientSignatureEnd(index)}
								/>

								<button
									type='button'
									onClick={() => handleClearPatientSignature(index)}
									className="text-xs absolute bg-gray-50 bottom-2 right-2 rounded-md px-3 py-1 flex items-center gap-2 uppercase">
									Clear
								</button>
								{index !== 0 &&
									patientSignature?.length > 1 && (
										<button
											type='button'
											onClick={() => handleRemovePatientSignature(index)}
											className="text-xs text-red-800 mt-3 absolute top-0 right-2 bg-red-50 rounded-md p-1 flex items-center gap-2 uppercase">
											Remove
										</button>
									)}
							</div>
						</div>
					))}

					{pharmacyInspectionMode === 1 ? null :
						patientSignature?.length < 5 && driverSelectedPharmacy?.patient_multi_sign === 1 && (
							<button
								type='button'
								onClick={handleAddPatientSignature}
								className="text-xs mt-3 bg-gray-50 rounded-md px-3 py-1 flex items-center gap-2 uppercase">
								Add Signature
							</button>
						)}

					<div className='text-xs mt-3 bg-gray-50 rounded-md px-3 py-1 flex items-center gap-2 uppercase' style={{ width: '232px' }}>
						<input type='checkbox' onChange={(e) => { setIsRefused(e.target.checked) }} value={isRefused} className='outline-none' id='sign' style={{ outline: 'none', border: '0.1px solid gray', borderRadius: '4px', }} />
						<label htmlFor='sign'>
							Patient Refused to Sign.
						</label>
					</div>

					{/* <div className='text-xs mt-3 bg-gray-50 rounded-md px-3 py-1 flex items-center gap-2 uppercase w-full'>
						<input type='checkbox' onChange={(e) => { setCovid19(e.target.checked) }} value={covid19} className='outline-none' id='covid19' style={{ outline: 'none', border: '0.1px solid gray', borderRadius: '4px', }} />
						<label htmlFor='covid19'>
							Health Canada's COVID - 19 - related CDSA Exemptions (M212)
						</label>
					</div> */}

				</React.Fragment>
			)}
		</div>
	);
};

export const UploadTriplicatesPatientSign = ({ pharmacistSignature, setPharmacistSignature,
	signatureErrors,
	setSignatureErrors
}) => {

	const sigRef = useRef();

	const handlePharmacistSignatureEnd = () => {
		const signatureDataURL = sigRef?.current?.toDataURL();
		if (signatureDataURL) {
			setPharmacistSignature(signatureDataURL);
		}
	}

	const handleClearSignature = () => {
		sigRef?.current?.clear();
		setPharmacistSignature(null);
	}

	useEffect(() => {
		if (pharmacistSignature) {
			setSignatureErrors({ ...signatureErrors, pharmacistSign: null })
		}
	}, [pharmacistSignature])

	return (
		<>
			<div className="p-4 bg-gray-100 rounded-md mb-3" style={{ border: signatureErrors.pharmacistSign }}>
				<div className="collapse-header flex items-center justify-between">
					<h2>Patient Signature</h2>
				</div>
					<div className="mt-3">
						<div className="w-full relative flex items-center justify-center rounded-md">
							<SignatureCanvas
								penColor="black"
								clearOnResize={false}
								canvasProps={{ className: 'signature' }}
								ref={sigRef}
								onEnd={handlePharmacistSignatureEnd}
							/>
							<button onClick={handleClearSignature}
								className="text-xs absolute bg-gray-50 bottom-2 right-2 rounded-md px-3 py-1 flex items-center gap-2 uppercase">
								<Icons.ClearPatientSignatureIcon />
								Clear</button>
						</div>
					</div>
			</div>
		</>
	)
}










