import React, {
    useEffect,
    useState
} from 'react';

import { useAllSelector } from "../../services/selectors/allSelector"
import { authSelectedPharmacist } from '../../services/apis/auth/selectedPharmacistID';
import { useAuthSelector } from '../../services/selectors/authSelector';

import * as Hooks from '../../hooks';

export const PharmacistSelection = ({ 
    signatureErrors,
    setSignatureErrors,
    setShowData
}) => {

    const dispatch = Hooks.useCustomDispatch()

    const { pharmacistDetail } = useAllSelector()
    const { pharmacyId } = useAuthSelector()

    const handlePharmacySelect = (e, id) => {
        dispatch(authSelectedPharmacist(id))
        setShowData(true);

        setTimeout(() => {
            const windowHeight = window.innerHeight;
            const scrollAmount = windowHeight / 3;
            window.scrollBy({ top: scrollAmount, behavior: "smooth" });
        }, 100);

        setSignatureErrors({ ...signatureErrors, pharmacistID: null, })
    };

    return (
        <React.Fragment>
            <div className='shadow-md flex grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 border border-gray-100 mb-2 rounded-md p-2'>
                {pharmacistDetail?.map((value, index) => (
                    <div key={index} className=" bg-white border border-gray-100 m-1 p-2  rounded-md">
                        <label htmlFor={`item-checkbox-${index}`} className="cursor-pointer flex items-center gap-2">
                            <div className="w-6 h-6 bg-gray-200 rounded-full overflow-hidden">
                                <img className="w-full h-full object-cover" alt='' src={value?.cover_photo} />
                            </div>
                            <div className="flex-grow">
                                <h1 className="text-sm font-medium text-gray-900 uppercase">{pharmacyId === 1502 ? 'XXXXX XX' + value?.name?.slice(-3) : value?.name?.toUpperCase()}</h1>
                            </div>
                            <div className="flex-shrink-0">
                                <input id={`item-checkbox-${index}`} type="radio" value={index} name="pharmacy-selection"
                                    className="peer" onChange={(e) => handlePharmacySelect(e, value.id)} />
                            </div>
                        </label>
                    </div>
                ))}
            </div>
        </React.Fragment>
    )
}