import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const setDriverDefaultRoute = createAsyncThunk('instoreDefaultRoute', async (route, { rejectWithValue }) => {

    try {
        const response = route
        const data = response
        return data;

    } catch (error) {
        return rejectWithValue(error.response)
    }
});

const initialState = {
    loading: false,
    data: null,
    error: null
}

const driverDefaultRouteSlice = createSlice({
    name: 'instoreDefaultRoute',
    initialState,

    extraReducers: (builder) => {
        builder.addCase(setDriverDefaultRoute.pending.type, (state) => {
            return {
                ...state,
                loading: true,
                data: null,
                error: null
            }
        })

        builder.addCase(setDriverDefaultRoute.fulfilled.type, (state, action) => {
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: null
            }
        })

        builder.addCase(setDriverDefaultRoute.rejected.type, (state, action) => {
            return {
                ...state,
                loading: false,
                data: null,
            }
        })
    }

})

export default driverDefaultRouteSlice.reducer